import { cva } from "class-variance-authority";

const root = cva(`relative overflow-hidden bg-contrast/5 w-full rounded-full h-2`, {
  variants: {
    size: {
      md: "h-2",
      sm: "h-1",
    },
    backgroundColor: {
      primary: "bg-contrast/5",
      transparent: "bg-transparent",
    },
  },
  defaultVariants: {
    size: "md",
  },
});

const indicator = cva(`w-full h-full left-0 top-0 rounded-full`, {
  variants: {
    color: {
      primary: "bg-primary",
      success: "bg-success",
      warning: "bg-warning",
      gradient: "bg-gradient-to-r from-gradient1 to-gradient1",
    },
  },
  defaultVariants: {
    color: "primary",
  },
});

export const progressStyles = { root, indicator };
