import * as ProgressPrimitive from "@radix-ui/react-progress";
import { VariantProps } from "class-variance-authority";
import { cn } from "../utils";
import { progressStyles } from "./progress.styles";

interface ProgressProps
  extends VariantProps<typeof progressStyles.indicator>,
    VariantProps<typeof progressStyles.root> {
  value: number;
  backgroundColor?: "primary" | "transparent";
  className?: string;
}

export const Progress = ({ value, color, size, backgroundColor, className }: ProgressProps) => {
  return (
    <ProgressPrimitive.Root className={cn(progressStyles.root({ size, backgroundColor }), className)} value={value}>
      <ProgressPrimitive.Indicator
        className={cn(progressStyles.indicator({ color }))}
        style={{ transform: `translateX(-${100 - value}%)` }}
      />
    </ProgressPrimitive.Root>
  );
};
